import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';

export default function () {
  const breakpoints = useBreakpoints(breakpointsTailwind);
  const sm = breakpoints.smaller('sm');
  const md = breakpoints.between('sm', 'md');
  const lg = breakpoints.between('md', 'lg');
  const xl = breakpoints.between('lg', 'xl');
  const xxl = breakpoints.between('xl', '2xl');
  const xxxl = breakpoints['2xl'];
  const mdOrLess = breakpoints.smaller('md');
  return { breakpoints, sm, md, lg, xl, xxl, xxxl, mdOrLess };
}
