import { HistoryIcon, TellerIcon } from '~/components/design-system/icons';
import GearIcon from '~~/components/design-system/icons/GearIcon.vue';
// teller
import AccountTeller from '../../components/account/teller/index.vue';
import AccountTellerWithdrawal from '../../components/account/teller/withdrawal.vue';
import AccountTellerWithDeposit from '../../components/account/teller/deposit.vue';
// history
import AccountHistory from '../../components/account/history/index.vue';
import AccountHistoryTransactionSummary from '../../components/account/history/transaction-summary.vue';
import AccountHistoryDetailedTransaction from '../../components/account/history/detailed-transaction.vue';
import AccountHistoryBonusSummary from '../../components/account/history/bonus-summary.vue';
import { AccountHistoryBonusWallet } from '#components';
// settings
import AccountSettingsPersonalDetails from '../../components/account/settings/personal-details.vue';
import AccountSettingsPasswordManagement from '../../components/account/settings/update-password.vue';
import AccountSettingsAccountSettings from '../../components/account/settings/account-settings.vue';
import AccountSettingsFica from '../../components/account/settings/document-verification.vue';
// log out
import AccountLogOut from '../../components/account/logout.vue';
import { useSiteStore } from '~/stores/site.store';

interface IUserAccountOptions {
  active: boolean;
  id: string;
  key: string;
  featureFlag?: string | null;
  runtimeOverride?: null | string;
  icon: any;
  component: any;
  includeInHamburger: boolean;
}

let accountOptions: IUserAccountOptions[] = [
  {
    active: false,
    id: 'teller',
    key: 'teller',
    icon: TellerIcon,
    component: AccountTeller,
    includeInHamburger: false,
  },
  {
    active: true,
    id: 'deposit',
    key: 'deposit',
    icon: null,
    component: AccountTellerWithDeposit,
    includeInHamburger: true,
  },
  {
    active: true,
    id: 'withdrawal',
    key: 'withdrawal',
    icon: null,
    component: AccountTellerWithdrawal,
    includeInHamburger: true,
  },
  {
    active: false,
    id: 'history',
    key: 'history',
    icon: HistoryIcon,
    component: AccountHistory,
    includeInHamburger: false,
  },
  {
    active: true,
    id: 'transaction-summary',
    key: 'transaction-summary',
    featureFlag: 'myaccount.transactionhistory',
    icon: null,
    component: AccountHistoryTransactionSummary,
    includeInHamburger: true,
  },
  {
    active: true,
    id: 'transaction-summary-detail',
    key: 'transaction-summary-detail',
    icon: null,
    component: AccountHistoryDetailedTransaction,
    includeInHamburger: false,
  },
  {
    active: false,
    id: 'bonus-summary',
    key: 'bonus-summary',
    icon: null,
    component: AccountHistoryBonusSummary,
    includeInHamburger: true,
  },
  {
    active: true,
    id: 'bonus-wallet',
    key: 'bonus-wallet',
    featureFlag: 'myaccount.bonusbalance',
    icon: null,
    component: AccountHistoryBonusWallet,
    includeInHamburger: true,
  },
  {
    active: true,
    id: 'personal-details',
    key: 'personal-details',
    featureFlag: 'myaccount.personaldetails',
    icon: null,
    component: AccountSettingsPersonalDetails,
    includeInHamburger: true,
  },
  {
    active: true,
    id: 'password-management',
    key: 'password-management',
    featureFlag: 'myaccount.updatepassword',
    icon: null,
    component: AccountSettingsPasswordManagement,
    includeInHamburger: true,
  },
  {
    active: true,
    id: 'account-settings',
    key: 'account-settings',
    featureFlag: 'myaccount.managesubscriptions',
    icon: null,
    component: AccountSettingsAccountSettings,
    includeInHamburger: true,
  },
  {
    active: true,
    id: 'document-verification',
    key: 'document-verification',
    featureFlag: 'website.documentverification',
    icon: null,
    component: AccountSettingsFica,
    includeInHamburger: true,
  },
  {
    active: true,
    id: 'logout',
    key: 'logout',
    icon: null,
    component: AccountLogOut,
    includeInHamburger: true,
  },
];

export const useAccountOptions = () => {
  const runtimeConfig = useRuntimeConfig();
  const siteStore = useSiteStore();
  const featureFlags = siteStore.getFeatureFlags;
  return accountOptions.map((accountOption) => {
    const ff = accountOption?.featureFlag;
    if (!!ff)
      return {
        ...accountOption,
        active: !!accountOption?.runtimeOverride
          ? runtimeConfig.public[accountOption?.runtimeOverride]
          : featureFlags.get(ff),
      };
    return accountOption;
  });
};

export const useUpsertAccountOptions = (request: IUserAccountOptions[]) => {
  accountOptions = request;
};
