<script setup lang="ts">
import { useAuthStore } from '~/stores/auth.store';
import Accordion from '../design-system/accordion-doc-verification.vue';
import type { IUploadedDocs } from '~/interfaces/dto/compliance/documentVerificationInterfaces';

const { $t } = useNuxtApp();
const { $complianceService, $authService } = useServices();
const auth = useAuthStore();
const idDocs: IUploadedDocs[] = reactive([]);
const bankDocs: IUploadedDocs[] = reactive([]);
const supportingDocs: IUploadedDocs[] = reactive([]);
const idStatus: globalThis.Ref<Number> = ref();
const bankStatus: globalThis.Ref<Number> = ref();
const supportingStatus: globalThis.Ref<Number> = ref();
const ficaApproved: globalThis.Ref<Boolean> = ref(false);
const loader = ref(false);

// function isImage(file: any): boolean {
//   const fileExtenstion = file.name.split('.').pop();
//   return ['jpg', 'jfif', 'jpeg', 'png', 'gif'].includes(fileExtention);
// }

function uploadFile(document: IUploadedDocs, thumbnail?) {
  //display newly uploading pending files
  const doc = document;
  const docType = doc.documentType;

  doc.thumbnail = thumbnail;
  if (docType === 0 || docType === 3) {
    idDocs.push(doc);
  } else {
    if (docType === 2) {
      bankDocs.push(doc);
    } else {
      supportingDocs.push(doc);
    }
  }
}

function deleteFile(file: IUploadedDocs) {
  const docType = file.documentType;
  if (docType === 0 || docType === 3) {
    //proofOfID
    for (let i = 0; i < idDocs.length; i++) {
      if (idDocs[i].documentId === file.documentId) {
        idDocs.splice(i, 1);
      }
    }
  } else {
    if (docType === 2) {
      //bank docs
      for (let i = 0; i < bankDocs.length; i++) {
        if (bankDocs[i].documentId === file.documentId) {
          bankDocs.splice(i, 1);
        }
      }
    } else {
      //supporting docs
      for (let i = 0; i < supportingDocs.length; i++) {
        if (supportingDocs[i].documentId === file.documentId) {
          supportingDocs.splice(i, 1);
        }
      }
    }
  }
}

async function getDocs() {
  await $complianceService.getDocuments().then((data) => {
    //if data not successful display error TODO
    if (data.isSuccessful) {
      const docs = data.data;
      //refer to notes for the rest of this algo
      docs.forEach((doc) => {
        if (doc.documentType === 0 || doc.documentType === 3) {
          //proofOfID
          idDocs.push(doc);
        } else {
          if (doc.documentType === 2) {
            bankDocs.push(doc);
          } else {
            //supporting docs
            supportingDocs.push(doc);
          }
        }
      });
    } else {
      //display error
    }
  });
}

function initDocVer() {
  idStatus.value = auth.getIdDocumentStatus;
  bankStatus.value = auth.getBankDocumentStatus;
  supportingStatus.value = auth.getSupportingDocumentStatus;
  ficaApproved.value = auth.getComplianceStatus > 0;
}

onBeforeMount(async () => {
  loader.value = true;
  await $authService.getUserExtended().then((data) => {
    loader.value = false;
  }); //calling this so that compliance values are updated if navigated immediately after post reg
  initDocVer();
  await getDocs();
});
</script>

<template>
  <LazyUserInterfaceGenericLoader full-screen v-if="loader" />
  <div
    class="mx-auto px-2 dark:text-white lg:w-190"
    :class="loader ? 'blur-4' : 'blur-0'"
  >
    <div class="doc-ver-gradient border-round-md" style="word-wrap: break-word">
      <div
        v-html="$t('doc-ver-explainer')"
        class="p-2 md:p-3 mb-3 line-height-3 mt-3"
      ></div>
    </div>
    <Accordion
      :header="'identity-verification'"
      :header-status="idStatus"
      :documents="idDocs"
      :active-index="!ficaApproved"
      document-type="ProofOfIdDocument"
      @delete-file="deleteFile"
      @upload-file="uploadFile"
    >
      <template #accordionContent>
        <div v-html="$t('doc-ver-id-verification')"></div>
      </template>
    </Accordion>

    <Accordion
      v-if="ficaApproved"
      :header="'bank-verification'"
      :header-status="bankStatus"
      :documents="bankDocs"
      document-type="ProofOfBankDocument"
      @delete-file="deleteFile"
      @upload-file="uploadFile"
    >
      <template #accordionContent>
        <div v-html="$t('doc-ver-bank-verification')"></div>
      </template>
    </Accordion>

    <Accordion
      v-if="ficaApproved"
      :header="'supporting-documentation'"
      :header-status="supportingStatus"
      :documents="supportingDocs"
      document-type="ProofOfResidenceDocument"
      @delete-file="deleteFile"
      @upload-file="uploadFile"
    >
      <template #accordionContent>
        <div class="my-2">
          <p class="pb-2 my-0">
            {{ $t('doc-ver-supporting-docs') }}
          </p>
        </div>
      </template>
    </Accordion>
  </div>
</template>
