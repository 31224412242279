<script setup>
import { useAuthStore } from '~/stores/auth.store';
import { unlockBodyScroll } from '~/utilities/ui-utilities';
const auth = useAuthStore();
const router = useRouter();
unlockBodyScroll();
setTimeout(() => {
  auth.logout();
  router.push('/');
  router.go();
}, 25);
</script>
<template></template>
