<script setup lang="ts">
import Button from '../design-system/button.vue';
import FileUpload from 'primevue/fileupload';
import { useToast } from 'primevue/usetoast';
import { useAuthStore } from '~/stores/auth.store';
import useImagePath from '../../composables/useImagePath';

interface IDocumentUpload {
  documentName: string;
  documentExtension: string;
  documentType: string;
  documentStatus: string;
  accountId: string;
  data: string | any;
}

interface IFileUploadProps {
  documentType: string | number; //TODO: convert to number when b/e makes changes
}

const { $t } = useNuxtApp();

const props = defineProps<IFileUploadProps>();

const { $complianceService } = useServices();
const auth = useAuthStore();

const emits = defineEmits(['uploadFile', 'deleteFile', 'uploading']);

const toast = useToast();

const totalSize = ref(0);
const totalSizePercent = ref(0);
const files = ref([]);
const docPreview = ref();

const onRemoveTemplatingFile = (file, removeFileCallback, index) => {
  removeFileCallback(index);
  totalSize.value -= parseInt(formatSize(file.size));
  totalSizePercent.value = totalSize.value / 10;
};

const onSelectedFiles = (event) => {
  files.value = event.files;
  files.value.forEach((file) => {
    totalSize.value += parseInt(formatSize(file.size));
    const fileURL = URL.createObjectURL(file);
    docPreview.value = fileURL;
  });
};

const uploadEvent = (callback) => {
  totalSizePercent.value = totalSize.value / 10;
  callback();
};

const formatSize = (bytes) => {
  const k = 1024;
  const dm = 1;
  // const sizes = this.$primevue.config.locale.fileSizeTypes;
  const sizes = ['b', 'kb', 'mb'];

  if (bytes === 0) {
    return `0 ${sizes[0]}`;
  }

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return `${formattedSize} ${sizes[i]}`;
};

function splitFileByExt(fileName: string) {
  const regexArr = fileName?.match(/^(.*?)(?:\.(\w+))?$/);
  const [full, name, ext] = regexArr;
  return { fileName: name, fileExt: ext };
}

async function docToByteArr(uploadedFile: any) {
  const file = uploadedFile as File;
  //returning promise to async await reader.onload so files only get sent once data is converted
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async function (f) {
      resolve(f.target.result.split(',')[1]);
    };
    reader.readAsDataURL(file);
  });
}

async function uploadFile(event) {
  emits('uploading', true);
  for (let i = 0; i < files.value.length; i++) {
    const file = files.value[i];
    const { fileName, fileExt } = splitFileByExt(file.name);
    const document: IDocumentUpload = {
      accountId: auth?.userId,
      documentName: fileName,
      documentType: String(props?.documentType),
      documentExtension: fileExt,
      documentStatus: 'AwaitingApproval',
      data: await docToByteArr(file),
    };

    await $complianceService.uploadDocument(document).then((data) => {
      //if successful
      if (data?.isSuccessful) {
        //call toast
        toast.add({
          severity: 'success',
          summary: $t('success'),
          detail: `${$t('jpc-doc-ver-file')} ${file.name} ${$t(
            'jpc-doc-ver-uploaded'
          )}`,
          life: 3000,
          group: 'toast',
        });

        //emit file object to parent
        emits('uploadFile', data.data, file.objectURL);
      } else {
        //error message
        toast.add({
          severity: 'error',
          summary: $t('error'),
          detail: $t('unable-to-upload-file'),
          life: 3000,
          group: 'toast',
        });
      }
    });
  }
  emits('uploading', false);
}

onMounted(() => {});
</script>

<template>
  <div class="card w-full">
    <div class="flex justify-content-center w-full mx-4">
      <Toast
        position="bottom-center"
        group="toast"
        :pt="{
          root: { style: 'width: 80%;' },
        }"
      />
    </div>
    <FileUpload
      @uploader="uploadFile($event)"
      customUpload
      :multiple="true"
      accept="image/*,application/pdf,.doc,.docx,.odt,.fodt,.ott"
      :maxFileSize="6000000"
      @select="onSelectedFiles($event)"
    >
      <template
        #header="{ chooseCallback, uploadCallback, clearCallback, files }"
      >
        <div
          class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2"
        >
          <div class="flex gap-2">
            <Button
              @click="chooseCallback()"
              icon="pi pi-plus"
              group="tertiary"
              :label="$t('choose')"
            />
          </div>
          <div>
            <Button
              @click="uploadEvent(uploadCallback)"
              icon="pi pi-upload"
              :label="$t('upload')"
              :group="files.length > 0 ? 'primary' : 'tertiary'"
              :disabled="!files || files.length === 0"
            />
            <Button
              @click="clearCallback()"
              icon="pi pi-times"
              :label="$t('cancel')"
              group="tertiary"
              :disabled="!files || files.length === 0"
              class="ml-2"
            />
          </div>
        </div>
      </template>
      <template #empty>
        <div class="flex align-items-center justify-content-center flex-column">
          <i
            class="pi pi-cloud-upload border-2 border-circle p-3 text-5xl dark:text-white border-400"
          />
          <p class="my-2 text-dark-700 dark:text-white">
            {{ $t('drag-and-drop-files') }}
          </p>
        </div>
      </template>
      <template
        #content="{
          files,
          uploadedFiles,
          removeUploadedFileCallback,
          removeFileCallback,
        }"
      >
        <div v-if="files.length > 0" class="w-full">
          <div class="flex flex-wrap p-0 gap-1 px-2">
            <div
              v-for="(file, index) of files"
              :key="file.name + file.type + file.size"
              class="card m-0 w-full flex flex-column align-items-center gap-1"
            >
              <div class="upload-container py-1">
                <div class="w-20 flex justify-content-center">
                  <img
                    role="presentation"
                    :alt="file.name"
                    :src="
                      file?.objectURL ||
                      useImagePath(
                        '/medialibraries/content.gmgamingsystems.com/jackpotcity/branding/pdf-default.png'
                      )
                    "
                    width="80"
                    height="40"
                    class="shadow-2 max-w-20 w-max"
                  />
                </div>
                <div class="flex flex-column ml-2 justify-content-center w-48">
                  <span class="font-semibold">
                    {{ file.name }} ({{ formatSize(file.size) }})
                  </span>
                  <span>
                    {{ $t('requested') }}:
                    {{
                      new Date()
                        .toLocaleDateString('en-GB')
                        .replaceAll('/', '-')
                    }}
                  </span>
                </div>

                <i
                  class="pi pi-trash text-lg text-dark-700 dark:text-white cursor-pointer ml-auto justify-content-center"
                  @click="
                    onRemoveTemplatingFile(file, removeFileCallback, index)
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </FileUpload>
  </div>
</template>

<style lang="scss">
// File upload themeing

.p-fileupload {
  &-buttonbar,
  &-content {
    display: flex;
    justify-content: center;
    background-color: transparent;
    border: none;
  }
  &-file {
    max-width: 150px;
    max-height: 150px;
  }
}

.p-fileupload-file {
  &-details,
  &-thumbnail {
    color: #464f60;
    .dark & {
      color: #fff;
    }
  }
}

.p-fileupload-content {
  padding: 0;
}

div.p-fileupload-content div.upload-container {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  width: 100%;
  padding-left: 8px !important;
  padding-right: 16px !important;
  font-size: 12px;
  border: 1px solid #dae0ed;
  border-radius: 6px;
  color: #2b303b;
  .dark & {
    color: #fff;
    border-color: #464f60;
    background-color: #1d2129;
  }
}

span.p-button.p-component.p-fileupload-choose {
  border: 1px solid #dae0ed;
  background-color: #fff;
  .dark & {
    background-color: #1d2129;
    border-color: #464f60;
  }
}
</style>

<style scoped lang="scss">
.dot {
  height: 8px;
  width: 8px;
  border-radius: 100%;
}
.status-div {
  cursor: default;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: right;
  align-items: center;
  border-radius: 4px;
  height: fit-content;
}
</style>
