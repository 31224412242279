<script setup lang="ts">
const Icon = useIcon();
import { DepositIcon } from 'assets/data/icons';

interface IBindings {
    'stroke-width': string;
    'fill-rule': string;
    'clip-rule': string;
    'stroke-linecap': string;
    'stroke-linejoin': string;
    viewBox: string;
    fill: string;
}

const bindings: IBindings = {
  'stroke-width': '2',
  'fill-rule': 'evenodd',
  'clip-rule': 'evenodd',
  'stroke-linecap': 'round',
  'stroke-linejoin': 'round',
  viewBox: '0 0 32 32',
  fill: 'none',
};
</script>
<template>
  <Icon :path="DepositIcon" class="w-7" :bindings="bindings" stroked />
</template>
