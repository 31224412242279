<script setup lang="ts">
import FileUploadV2 from '~/components/design-system/file-upload-v2.vue';
import GenericLoader from '~/components/user-interface/generic-loader.vue';
import { useToast } from 'primevue/usetoast';
const { $t } = useNuxtApp();
const { $complianceService } = useServices();
import useImagePath from '../../composables/useImagePath';

interface IUploadedDocs {
  thumbnail?: string;
  documentId: string;
  documentName: string;
  documentType: number;
  documentStatus: number;
  dateRequested: Date;
  documentSize: number;
  externalSystemId: string;
  documentStatusDescription: string;
}

interface IAccordionProps {
  header: string;
  headerStatus?: number;
  documents?: IUploadedDocs[];
  documentType: string | number;
  activeIndex?: boolean;
}

const colorMode = useColorMode();

const toast = useToast();
const props = withDefaults(defineProps<IAccordionProps>(), {
  activeIndex: false,
});
const emits = defineEmits(['uploadFile', 'deleteFile']);
const isUploading = ref(false);
const isDeleting = ref(false);

const statusText = computed(() => {
  switch (props.headerStatus) {
    case 0:
      return 'awaiting-upload';
    case 1:
      return 'pending';
    case 2:
      return 'valid';

    default:
      return '';
  }
});

function getStatusColour(status: number) {
  //awaiting-upload - blue
  if (status === 0) {
    if (colorMode.preference === 'dark') {
      return 'primary-blue-400';
    }
    return 'primary-blue-600';
  }

  //awaiting approval - orange/gold
  if (status === 1) {
    if (colorMode.preference === 'dark') {
      return 'primary-gold-500';
    }
    return 'secondary-gold-700';
  }

  //approved - green
  if (status === 2) {
    return 'success-500';
  }

  //bad qual, invalid, expired, deceased - red
  if (status > 2) {
    return 'error-500';
  }
}
//event: FileUploadUploadEvent
function uploadFile(document, thumbnail) {
  emits('uploadFile', document, thumbnail);
}

function activateLoader(flag: true) {
  isUploading.value = flag;
}

async function deleteFile(document: IUploadedDocs) {
  isDeleting.value = true;
  //make call
  //Api call for delete
  await $complianceService.deleteDocument(document.documentId).then((data) => {
    if (data.isSuccessful) {
      //If Successful
      toast.add({
        severity: 'success',
        summary: $t('success'),
        detail: `${$t('jpc-doc-ver-file')} ${document.documentName} ${$t(
          'jpc-doc-ver-deleted'
        )}`,
        life: 3000,
        group: 'toast',
      });
      emits('deleteFile', document);
    } else {
      // display error
      toast.add({
        severity: 'error',
        summary: $t('error'),
        detail: $t('unable-to-delete-file'),
        life: 3000,
        group: 'toast',
      });
    }
  });

  isDeleting.value = false;
}
</script>

<template>
  <Toast
    position="bottom-center"
    group="toast"
    :pt="{
      root: { style: 'width: 80%;' },
    }"
  />
  <Accordion
    class="h-fit mb-3 doc-ver-accordion"
    :active-index="props.activeIndex ? 0 : null"
  >
    <AccordionTab header-class="b-0 mb-3">
      <template #header>
        <div class="flex flex-row align-items-center">
          <p class="my-2.5 font-bold text-base">{{ $t(props.header) }}</p>
          <div
            class="status-div ml-3 mr-2 bg-light-50 dark:bg-dark-900"
            v-if="
              statusText &&
              !(header === 'bank-verification' && props.headerStatus === 0)
            "
          >
            <div
              class="dot mx-2"
              :class="`bg-` + getStatusColour(props.headerStatus)"
            />
            <p
              class="mr-3 font-normal text-xs capitalize"
              :class="`text-` + getStatusColour(props.headerStatus)"
              style="margin-top: 10px; margin-bottom: 10px"
            >
              {{ $t(statusText || 'pending') }}
            </p>
          </div>
        </div>
      </template>
      <div
        class="text-xs dark:text-white b-0 b-bottom-1 b-light-400 dark:b-dark-500 pt-2 px-2 sm:px-3 sm:pt-2"
      >
        <slot name="accordionContent" />
      </div>
      <div
        class="footer dark:text-white bg-light-200 dark:bg-dark-700 p-2 pt-3 xs:p-3"
      >
        <p class="mt-0 mb-2 text-xs font-bold">
          {{
            props.documents.length > 0
              ? $t('your-documents')
              : props.headerStatus === 2
              ? ''
              : $t('please-upload-documents')
          }}
        </p>
        <div
          v-if="props.documents && props.documents.length > 0"
          class="mx-1.5"
          :class="isDeleting ? 'blur-4' : 'blur-0'"
        >
          <GenericLoader v-if="isDeleting" container />
          <div
            v-for="(doc, index) in props.documents"
            class="flex flex-row justify-content-around w-full dark:bg-dark-800 b-1 border-round-md dark:b-dark-500 b-light-400 pl-2 pr-2 sm:pr-3 mb-2"
          >
            <div
              class="flex flex-row align-items-center w-full justify-content-start"
            >
              <div class="w-20 flex justify-content-center">
                <img
                  role="presentation"
                  :src="
                    doc?.thumbnail ||
                    useImagePath(
                      '/medialibraries/content.gmgamingsystems.com/jackpotcity/branding/no-image-available.png'
                    )
                  "
                  width="80"
                  height="40"
                  class="shadow-2 max-w-20 w-max"
                />
              </div>
              <div class="flex flex-column">
                <p
                  class="mt-2 mb-0 font-bold text-xs"
                  :class="`text-` + getStatusColour(doc.documentStatus)"
                >
                  {{ doc.documentName }}
                </p>
                <div class="flex flex-row text-xs">
                  <p class="my-0 font-semibold mr-1 capitalize">
                    {{ $t('requested') }}:
                  </p>
                  <p class="mt-0 mb-2">
                    {{
                      new Date(doc.dateRequested)
                        .toLocaleDateString('en-GB')
                        .replaceAll('/', '-')
                    }}
                  </p>
                </div>
              </div>
              <div
                class="status-div bg-light-50 dark:bg-dark-900 ml-2 mr-2 sm:ml-3"
              >
                <div
                  class="dot mx-2"
                  :class="`bg-` + getStatusColour(doc.documentStatus)"
                />
                <p
                  class="mr-3 font-normal text-xs"
                  :class="`text-` + getStatusColour(doc.documentStatus)"
                  style="margin-top: 10px; margin-bottom: 10px"
                >
                  {{ $t(doc.documentStatusDescription || 'pending') }}
                </p>
              </div>
            </div>
            <i
              class="pi pi-trash text-lg align-self-center cursor-pointer text-dark-700 dark:text-white mr-2"
              @click="deleteFile(doc)"
            />
          </div>
        </div>
        <div
          class="flex flex-column align-content-center flex-wrap w-full dark:bg-dark-600 border-round-md"
          :class="isUploading ? 'blur-4' : 'blur-0'"
        >
          <GenericLoader v-if="isUploading" container />

          <FileUploadV2
            v-if="props.headerStatus !== 2"
            @upload-file="uploadFile"
            @uploading="activateLoader"
            :document-type="props.documentType"
          />
        </div>
      </div>
    </AccordionTab>
  </Accordion>
</template>

<style scoped>
p {
  font-family: 'inter';
}

.dot {
  height: 8px;
  width: 8px;
  border-radius: 100%;
}
.status-div {
  cursor: default;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: right;
  align-items: center;
  border-radius: 4px;
  height: fit-content;
}

.footer {
  width: 100%;
  padding-top: 12px;

  padding-bottom: 24px;
}
</style>

<style lang="scss">
.dark {
  .doc-ver-accordion .p-accordion-tab .p-accordion-content {
    background-color: #1d2129;
    border-color: #464f60;
  }

  .doc-ver-accordion .p-accordion-header {
    background-color: #2b303b;
    border-color: #464f60;
  }

  .doc-ver-accordion .p-accordion-content {
    border-color: #464f60;
  }
}

.doc-ver-accordion .p-accordion-header {
  background-color: #eceff6; //wrong value (need light mode design)
  border: 1px solid #dae0ed;
  border-radius: 6px 6px 0px 0px;

  a.p-accordion-header-action.p-accordion-header-link {
    border: none;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.doc-ver-accordion .p-accordion-content {
  border: 1px solid #dae0ed;
  border-top: 0px;
}

.doc-ver-accordion .p-accordion-tab .p-accordion-content {
  background-color: #fff;
  border: 1px solid #dae0ed;
  border-top: 0px;
  border-radius: 0px 0px 6px 6px;
  padding: 0px;
}

.doc-ver-accordion .p-accordion-header-link.p-accordion-header-action {
  // moving accordion arrow to the right
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.doc-ver-accordion .p-accordion .p-accordion-header .p-accordion-header-link {
  padding-left: 8px;
  padding-right: 8px;
}
</style>
