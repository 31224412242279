<script setup lang="ts">
const Icon = useIcon();
import { WithdrawIcon } from 'assets/data/icons';

interface IBindings {
    'stroke-width': string;
    'fill-rule': string;
    'clip-rule': string;
    viewBox: string;
}

const bindings: IBindings = {
  'stroke-width': '2',
  'fill-rule': 'evenodd',
  'clip-rule': 'evenodd',
  viewBox: '0 0 27 27',
};
</script>
<template>
  <Icon :path="WithdrawIcon" class="w-7" :bindings="bindings" filled />
</template>
