<script setup lang="ts">
import Button from '~~/components/design-system/button.vue';
import Password from '~~/components/design-system/form/password.vue';
import { CheckCircleIcon } from '@heroicons/vue/outline/index.js';
import CircleIcon from '~/components/design-system/icons/CircleIcon.vue';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import GenericLoader from '~/components/user-interface/generic-loader.vue';

const formLoading: globalThis.Ref<boolean> = ref(false);
const { $authService } = useServices();
const auth = useAuthStore();
const site = useSiteStore();
const pwd = ref(null);
const newPwd = ref(null);

const countryCode: string = 'za';

const valid: globalThis.Ref<boolean> = ref(false);
const passwordValid: globalThis.Ref<boolean> = ref(false);
const passwordEmpty: globalThis.Ref<boolean> = ref(true);
const errorCode: globalThis.Ref<string> = ref('');

const navigateToSuccess = () => {
  // navigate to reset success
  auth.toggleAccountModal(false);
  site.activateModal('passwordResetStepSuccess');
};

// Submit new password
const confirmPasswordChange = () => {
  formLoading.value = true;

  const accountId: string = auth.user_id;
  const password = pwd.value;
  const confirmPassword = newPwd.value;

  $authService
    .passwordResetLoggedIn({
      accountId,
      countryCode,
      password,
      confirmPassword,
    })
    .then(({ isSuccessful, error, metadata }) => {
      if (isSuccessful) {
        // redirect to otp success
        navigateToSuccess();
      } else {
        errorCode.value = error.message;
      }
    })
    .finally(() => {
      formLoading.value = false;
    });
};

function passwordChange() {
  //run password regex to enable submit button
  const regex = new RegExp(
    '^(?!.*\\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*\\W)(?:.{8,20})$'
  );
  passwordValid.value = regex.test(pwd.value);

  if (pwd.value === '') {
    valid.value = false;
    if (newPwd.value === '') {
      passwordEmpty.value = true;
    }
  } else {
    passwordEmpty.value = false;
    valid.value = !!(pwd.value === newPwd.value && passwordValid.value);
  }
}
</script>

<template>
  <!-- step 3: enter new password -->
  <div class="px-3 pt-2 enter-password">
    <!-- Heading -->
    <div class="text-md font-bold">
      <p class="mt-1 mb-1 text-black dark:text-white text-center">
        {{ $t('update-password') }}
      </p>
    </div>

    <!-- Sub heading -->
    <div class="text-xs text-black dark:text-white">
      <p class="mb-0 ml-1">{{ $t('Enter-new-password') }}</p>
    </div>

    <div class="grid">
      <!-- New password -->
      <div class="field col-12 mb-0 pb-0 forgot-password">
        <div class="p-inputgroup w-full">
          <Password
            :label="$t('new-password')"
            formKey="pwd"
            :feedback="true"
            v-model="pwd"
            class="w-full pb-0"
            @update:modelValue="passwordChange()"
          />
        </div>
      </div>

      <!-- Confirm password -->
      <div class="field col-12 mb-0 pb-0 forgot-password">
        <div class="p-inputgroup w-full">
          <Password
            :label="$t('confirm-password')"
            formKey="newPwd"
            :feedback="false"
            v-model="newPwd"
            class="w-full pb-0"
            @update:modelValue="passwordChange()"
          />
        </div>
      </div>

      <!-- Passwords must match -->
      <div class="field col-12 mb-0 py-0">
        <p class="my-1 flex flex-row justify-content-left">
          <CircleIcon v-if="!valid" class="w-4 circle-icon" />
          <CheckCircleIcon
            v-else
            class="text-primary-blue-700 icon-success--border"
          />
          <span
            class="text-xs font-light"
            :class="
              !passwordEmpty && !valid
                ? 'text-red-600'
                : 'dark:text-white text-dark-700'
            "
          >
            {{ $t('Passwords-must-match-and-be-valid') }}
          </span>
        </p>
      </div>

      <!-- Error messaging -->
      <div class="field col-12 mb-0 pt-0 flex justify-content-center">
        <small v-if="errorCode" class="p-error text-xs">
          {{ errorCode }}
          <!-- {{ fieldErrors.phone }} -->
        </small>
      </div>
    </div>
  </div>

  <!-- Buttons -->
  <div
    class="field col-12 mb-0 bg-light-200 dark:bg-dark-900 text-sm flex flex-row justify-content-center p-2"
  >
    <Button
      class="m-2 uppercase w-full justify-content-center text-sm"
      @click="confirmPasswordChange()"
      :disabled="!valid"
    >
      {{ $t('update-password') }}
    </Button>
  </div>
  <GenericLoader v-if="formLoading" container />
</template>
<style scoped>
.circle-icon {
  margin-right: 5px;
}
</style>
