<script setup lang="ts">
import {
  PlusIcon,
  RefreshIcon,
  ChevronDownIcon,
  CashIcon,
} from '@heroicons/vue/outline/index.js';
import Button from '../../components/design-system/button.vue';
import { useAuthStore } from '~/stores/auth.store';
import Modal from '~/components/design-system/modal.vue';
import GenericLoader from '~/components/user-interface/generic-loader.vue';
import GenericLoaderSmall from '../user-interface/generic-loader-small.vue';
import { ExclamationIcon } from '../design-system/icons/index';

const auth = useAuthStore();
const { $walletService } = useServices();
const zeroBalance = ref(0);
const activeLoader = ref(false);
interface IUserCashBalanceProps {
  type?: 'hamburger' | 'user-nav-bar';
  showHideBalance?: boolean;
}
const props = withDefaults(defineProps<IUserCashBalanceProps>(), {
  type: 'user-nav-bar',
  showHideBalance: true,
});

const refreshBalances = async () => {
  activeLoader.value = true;
  await $walletService.fetchBalances();
  activeLoader.value = false;
};

watch(
  () => auth.loggedIn,
  async (val) => {
    if (val) await refreshBalances();
  }
);

function toggleHideBalance() {
  auth.toggleBalances(!auth.displayAccountBalances);
}
const config = useRuntimeConfig();
</script>

<template>
  <div class="flex flex-row align-items-center">
    <div
      v-if="showHideBalance"
      class="text-2xl mr-2 cursor-pointer text-black dark:text-white"
      @click="toggleHideBalance()"
    >
      <i
        class="pi"
        :class="auth.displayAccountBalances ? 'pi-eye' : 'pi-eye-slash'"
      />
    </div>

    <div
      class="flex dark:bg-dark-900 bg-light-200 border-round-md mr-1 relative"
    >
      <div
        v-if="
          !auth.accountBalances?.cashBalance &&
          auth.accountBalances?.cashBalance !== 0 &&
          props.type !== 'hamburger'
        "
        class="flex w-full relative justify-content-center"
        style="left: 50%"
      >
        <GenericLoaderSmall class="small-loader" />
      </div>

      <div
        class="border-round-md flex justify-content-between align-items-center leading-tight text-xs bg-light-200 dark:bg-dark-900 px-1 h-8 text-black dark:text-white mr-1 relative"
        :class="
          !auth.accountBalances?.cashBalance &&
          auth.accountBalances?.cashBalance !== 0 &&
          props.type !== 'hamburger'
            ? 'blur-4'
            : 'blur-0'
        "
      >
        <div class="mr-2">
          <div class="truncate font-bold capitalize">
            {{ $t('cash-balance') }}
          </div>
          <div
            v-if="auth.accountBalances?.cashBalance < 0"
            class="flex justify-content-center"
          >
            <ExclamationIcon
              class="text-primary-gold-500 w-4"
              tooltip="could-not-retrieve-balances"
            />
          </div>
          <div
            v-else-if="auth.displayAccountBalances"
            class="flex"
            v-html="
              useFormatCurrency(
                auth?.accountBalances?.cashBalance || zeroBalance
              )
            "
          />
          <div v-else>●●●●●</div>
        </div>
      </div>
    </div>
    <Modal
      v-if="props.type === 'user-nav-bar'"
      :title="$t('Account Balances')"
      :breakpoints="{ '425px': '326px', '360px': '320px' }"
      :width="`320px`"
      :active="false"
      @close-modal=""
    >
      <template #modal-activator>
        <Button size="icon-md" group="tertiary" class="mr-1">
          <ChevronDownIcon class="w-6" />
        </Button>
      </template>
      <template #modal-content>
        <GenericLoader v-if="activeLoader" :key="activeLoader" container />
        <div
          class="flex justify-content-between align-items-center px-2 text-black dark:text-white text-xs"
        >
          <div
            v-if="showHideBalance"
            class="text-2xl mr-2 cursor-pointer text-black dark:text-white"
            @click="toggleHideBalance()"
          >
            <i
              class="pi"
              :class="auth.displayAccountBalances ? 'pi-eye' : 'pi-eye-slash'"
            />
          </div>
          <div class="w-full">
            <div
              class="flex justify-content-between bg-white dark:bg-dark-900 border-round-md m-2 ml-0"
            >
              <div class="pl-1 pt-1">
                <span class="block font-bold">
                  {{ $t('cash') }}
                </span>
                <div
                  v-if="auth.accountBalances?.cashBalance < 0"
                  class="flex justify-content-center"
                >
                  <ExclamationIcon
                    class="text-primary-gold-500 w-4"
                    tooltip="could-not-retrieve-balances"
                  />
                </div>
                <span
                  v-else-if="auth.displayAccountBalances"
                  class="block"
                  v-html="
                    useFormatCurrency(
                      auth?.accountBalances?.cashBalance || zeroBalance
                    )
                  "
                />
                <div v-else>●●●●●</div>
              </div>
              <div
                class="button--primary-gold cursor-pointer border-round-md text-center h-2rem m-1"
                @click="refreshBalances()"
              >
                <RefreshIcon class="w-2rem p-1" />
              </div>
            </div>
          </div>
          <div class="w-full">
            <div
              class="flex justify-content-between bg-white dark:bg-dark-900 border-round-md m-2 mr-0"
            >
              <div class="pl-1 pt-1">
                <span class="block font-bold">
                  {{ $t('bonus') }}
                </span>
                <div
                  v-if="auth.activeBonus?.totalAwardedBonusAmount < 0"
                  class="flex justify-content-center"
                >
                  <ExclamationIcon
                    class="text-primary-gold-500 w-4"
                    tooltip="could-not-retrieve-balances"
                  />
                </div>
                <span
                  v-else-if="auth.displayAccountBalances"
                  class="block"
                  v-html="
                    useFormatCurrency(
                      auth.activeBonus?.totalAwardedBonusAmount ||
                        auth?.accountBalances?.playThroughBalance ||
                        zeroBalance
                    )
                  "
                />
                <div v-else>●●●●●</div>
              </div>
              <div
                class="button--primary-gold cursor-pointer border-round-md text-center h-2rem m-1"
                @click="refreshBalances()"
              >
                <RefreshIcon class="w-2rem p-1" />
              </div>
            </div>
          </div>
        </div>
        <div class="flex w-full p-3 bg-white dark:bg-dark-900">
          <Button
            group="primary-gold"
            class="w-full"
            @click="auth.setAccountModalPage('deposit')"
          >
            <span class="text-center w-full">{{ $t('deposit-now') }}</span>
          </Button>
        </div>
      </template>
    </Modal>
    <Button
      v-if="props.type === 'user-nav-bar'"
      group="primary-gold"
      class="cursor-pointer deposit mr-1"
      size="small"
      @click="auth.setAccountModalPage('deposit')"
    >
      <CashIcon class="w-5" />
      <span class="hidden md:block ml-1">{{ $t('deposit') }}</span>
    </Button>
    <div
      class="border-round-md flex justify-content-between align-items-center leading-tight text-xs bg-light-200 dark:bg-dark-900 px-1 h-8 text-black dark:text-white mr-2"
      v-if="props.type === 'hamburger'"
    >
      <div class="mr-2">
        <div class="truncate font-bold capitalize">
          {{ $t('bonus-balance') }}
        </div>
        <div
          v-if="auth.activeBonus?.totalAwardedBonusAmount < 0"
          class="flex justify-content-center"
        >
          <ExclamationIcon
            class="text-primary-gold-500 w-4"
            tooltip="could-not-retrieve-balances"
          />
        </div>
        <div
          v-else-if="auth.displayAccountBalances"
          v-html="
            useFormatCurrency(
              auth.activeBonus?.totalAwardedBonusAmount ||
                auth?.accountBalances?.playThroughBalance ||
                zeroBalance
            )
          "
        />
        <div v-else>●●●●●</div>
      </div>
    </div>
    <Modal
      v-if="props.type === 'hamburger'"
      :title="$t('Account Balances')"
      :breakpoints="{ '425px': '326px', '360px': '320px' }"
      :width="`320px`"
      :active="false"
      @close-modal=""
    >
      <template #modal-activator>
        <Button
          group="primary-gold"
          class="cursor-pointer ml-1.5"
          size="icon-md"
          @click="true"
        >
          <PlusIcon />
        </Button>
      </template>
      <template #modal-content>
        <GenericLoader v-if="activeLoader" :key="activeLoader" container />
        <div
          class="flex justify-content-between pl-2 pr-2 text-black dark:text-white text-xs"
        >
          <div
            v-if="showHideBalance"
            class="text-2xl mr-2 cursor-pointer text-black dark:text-white flex flex-column justify-content-center"
            @click="toggleHideBalance()"
          >
            <i
              class="pi"
              :class="auth.displayAccountBalances ? 'pi-eye' : 'pi-eye-slash'"
            />
          </div>
          <div class="w-full">
            <div
              class="flex justify-content-between bg-white dark:bg-dark-900 border-round-md m-2 mr-0"
            >
              <div class="pl-1 pt-1">
                <span class="block font-bold">
                  {{ $t('cash') }}
                </span>
                <div
                  v-if="auth.accountBalances?.cashBalance < 0"
                  class="flex justify-content-center"
                >
                  <ExclamationIcon
                    class="text-primary-gold-500 w-4"
                    tooltip="could-not-retrieve-balances"
                  />
                </div>
                <span
                  v-else-if="auth.displayAccountBalances"
                  class="block"
                  v-html="
                    useFormatCurrency(
                      auth?.accountBalances?.cashBalance || zeroBalance
                    )
                  "
                />
                <div v-else>●●●●●</div>
              </div>
              <div
                class="button--primary-gold cursor-pointer border-round-md text-center h-2rem m-1"
                @click="refreshBalances()"
              >
                <RefreshIcon class="w-2rem p-1" />
              </div>
            </div>
          </div>
          <div class="w-full">
            <div
              class="flex justify-content-between bg-white dark:bg-dark-900 border-round-md m-2 mr-0"
            >
              <div class="pl-1 pt-1">
                <span class="block font-bold">
                  {{ $t('bonus') }}
                </span>
                <div
                  v-if="auth.activeBonus?.totalAwardedBonusAmount < 0"
                  class="flex justify-content-center"
                >
                  <ExclamationIcon
                    class="text-primary-gold-500 w-4"
                    tooltip="could-not-retrieve-balances"
                  />
                </div>
                <span
                  v-else-if="auth.displayAccountBalances"
                  class="block"
                  v-html="
                    useFormatCurrency(
                      auth.activeBonus?.totalAwardedBonusAmount ||
                        auth?.accountBalances?.playThroughBalance ||
                        zeroBalance
                    )
                  "
                />
                <div v-else>●●●●●</div>
              </div>
              <div
                class="button--primary-gold cursor-pointer border-round-md text-center h-2rem m-1"
                @click="refreshBalances()"
              >
                <RefreshIcon class="w-2rem p-1" />
              </div>
            </div>
          </div>
        </div>
        <div class="flex w-full p-3 bg-white dark:bg-dark-900">
          <Button
            group="primary-gold"
            size="large"
            class="w-full"
            @click="auth.setAccountModalPage('deposit')"
          >
            <span class="text-center w-full">{{ $t('deposit-now') }}</span>
          </Button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<style scoped lang="css">
.deposit.button--icon-sm {
  width: 4.2rem;
  font-size: 1.2em;
  padding: 0 5px;
  margin-right: -3px;
}
.small-loader {
  position: absolute;
  z-index: 100;
  width: 100%;
  bottom: 16px;
}
</style>
