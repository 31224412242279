<script setup lang="ts">
import Pagination from '~/components/design-system/pagination.vue';
import { useAuthStore } from '~/stores/auth.store';
import GenericError from '~/components/user-interface/generic-error.vue';
import GenericLoader from '~/components/user-interface/generic-loader.vue';
import { formatTransactionDate } from '~/utilities/data-transformers';
import { useServices } from '../../../composables/useServices';

const currentPage: globalThis.Ref<number> = ref(0);
const totalPages: globalThis.Ref<number> = ref(0);
const recordsPerPage: globalThis.Ref<number> = ref(0);
const showingStart: globalThis.Ref<number> = ref(0);
const showingEnd: globalThis.Ref<number> = ref(0);
const totalResults: globalThis.Ref<number> = ref(0);
const activeLoader: globalThis.Ref<boolean> = ref(true);
const auth = useAuthStore();
const { $walletService } = useServices();
let currentTransactions;
const errorMessage = ref('sorry-cant-find-results');
const transactionId = ref('');
const showDetailTransaction = ref(false);

interface ITableData {
  transactionId: number;
  date: string;
  type: string;
  amount: number;
  expires: string;
}

interface ICategories {
  text: string;
  value: string;
}

interface IData {
  accountId: string;
  dateFrom: Date;
  dateTo: Date;
  pageIndex: number;
  pageSize: number;
  token: string;
}

const newData = reactive({ value: [] });
//hard coded values that we will grab from API
const categories: ICategories[] = [{ text: 'Bonus', value: 'bonus' }];

const headers = ref([
  {
    field: 'transactionId',
    header: 'Transaction ID',
    currency: false,
  },
  {
    field: 'date',
    header: 'Date',
    currency: false,
  },
  {
    field: 'type',
    header: 'Type',
    currency: false,
  },
  {
    field: 'amount',
    header: 'Amount',
    currency: true,
  },
  {
    field: 'expires',
    header: 'Expires',
    currency: false,
  },
]);

const mobileHeaders = ref([
  {
    field: 'expires',
    header: 'Expires',
    currency: false,
  },
  {
    field: 'type',
    header: 'Type',
    currency: false,
  },
  {
    field: 'amount',
    header: 'Amount',
    currency: true,
  },
]);
function readData() {
  currentPage.value = 1;
  recordsPerPage.value = 20;
  showingStart.value = 1;
  showingEnd.value = recordsPerPage.value;
}

const data: IData = {
  accountId: auth.userId + '',
  dateFrom: new Date('2022-10-01T13:57:24.525Z'),
  dateTo: new Date(),
  pageIndex: 1,
  pageSize: 20,
  token: auth.currentAccessToken + '',
};

async function getTransactions(pageIndex: number) {
  newData.value = [];
  activeLoader.value = true;
  data.pageIndex = pageIndex;
  try {
    await $walletService.fetchBonusTransactions(data).then(
      ({ data }) => {
        totalResults.value = data.totalRecords;
        totalPages.value = data.totalPages;
        currentPage.value = data.pageIndex;

        activeLoader.value = false;
        data.transactionLogs.forEach((record) => {
          const recordDate = new Date(record.createdDateTime);
          const expDate = new Date(
            recordDate.setDate(recordDate.getDate() + 366)
          ); //1 year from creation date
          const tableRow: ITableData = {
            transactionId: record.sequenceId,
            date: formatTransactionDate(new Date(record.createdDateTime)),
            type: record.note || record.transactionTypeDescription,
            amount: record.isCredit ? record.amount : -1 * record.amount,
            expires: formatTransactionDate(expDate), //API needs to expose expiry date
          };
          newData.value.push(tableRow);
        });
        currentTransactions = data.transactionLogs;
      },
      (err) => {
        activeLoader.value = false;
        errorMessage.value = 'an-error-occurred';
        console.error(err);
      }
    );
  } catch (e) {
    activeLoader.value = false;
    errorMessage.value = 'an-error-occurred';
  }
}

function next() {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
    getTransactions(currentPage.value);
  }
}

function select(pageNo: number) {
  currentPage.value = pageNo;
  getTransactions(currentPage.value);
}

function previous() {
  if (currentPage.value > 1) {
    currentPage.value--;
    getTransactions(currentPage.value);
  }
}

function changeCategory(newCatTR: number, category: string) {
  totalResults.value = newCatTR;
  totalPages.value = Math.ceil(totalResults.value / recordsPerPage.value);
  currentPage.value = 1;
}

function updatePage(start: number, end: number, total?: number) {
  showingStart.value = start;
  showingEnd.value = end;
  totalPages.value = total ?? totalPages.value; //if total exists set totalPages to total
}

function search(totalRecords: number) {
  totalResults.value = totalRecords;
  currentPage.value = 1;
  if (totalRecords && totalRecords != 0) {
    totalPages.value = Math.ceil(totalResults.value / recordsPerPage.value);
  } else {
    showingStart.value = 0;
    totalPages.value = 1;
  }
}

function showDetail(value) {
  transactionId.value = currentTransactions.find(
    (t) => t.sequenceId === value
  ).financialReference;
  toggleShowDetail();
}
function toggleShowDetail() {
  showDetailTransaction.value = !showDetailTransaction.value;
}

onMounted(async () => {
  readData();
  await getTransactions(data.pageIndex);
});

const display = computed(() => activeLoader.value);
</script>

<template>
  <div v-if="!showDetailTransaction">
    <LazyUserAccountSettingsHeader
      :title="$t('bonus-summary')"
      return-route="history"
    />
    <GenericLoader v-if="display" :standalone="true" />
    <div v-else>
      <LazyDesignSystemTable
        v-if="newData.value.length"
        :originalData="newData.value"
        :categories="categories"
        :headers="headers"
        :mobile-headers="mobileHeaders"
        :totalPages="totalPages"
        :currentPage="currentPage"
        :recordsperPage="recordsPerPage"
        :total-results="totalResults"
        :mobile-b-p="`lg`"
        :clickable="true"
        @changeCategory="changeCategory"
        @update-page="updatePage"
        @search="search"
        @row-click="showDetail"
      >
        <template #pagination>
          <div
            class="flex place-content-center justify-content-center py-3 sticky bottom-0 bg-light-50 dark:bg-dark-800"
          >
            <Pagination
              @next="next"
              @previous="previous"
              @select="select"
              :currentPage="currentPage"
              :totalPages="totalPages"
              :showing-start="showingStart"
              :showing-end="showingEnd"
              :total-results="totalResults"
            />
          </div>
        </template>
      </LazyDesignSystemTable>
      <GenericError v-else state="danger" class="h-fit my-3">
        {{ $t(errorMessage) }}
      </GenericError>
    </div>
  </div>
  <div v-if="showDetailTransaction">
    <AccountHistoryDetailedTransaction
      @back="toggleShowDetail()"
      title="bonus-summary"
      :transaction-id="transactionId"
    />
  </div>
</template>
