<script setup>
const colorMode = useColorMode();
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 70"
    height="20"
    width="20"
  >
    <path
      d="M41.51,55.64c-3.13,1.31-6.56,2.04-10.16,2.04-14.53,0-26.34-11.82-26.34-26.34S16.82,5,31.34,5s26.34,11.82,26.34,26.34c0,.52-.02,1.04-.05,1.56,1.73.13,3.39.51,4.93,1.14.08-.89.12-1.79.12-2.69C62.69,14.06,48.62,0,31.34,0S0,14.06,0,31.34s14.06,31.34,31.34,31.34c4.59,0,8.95-1,12.89-2.78-1.11-1.26-2.04-2.69-2.72-4.26Z"
      :fill="colorMode.preference === 'dark' ? '#fff' : '#2b303b'"
    />
    <path
      d="M40.33,30.53l-12.11-8.8c-.9-.65-2.16,0-2.15,1.1l.08,17.41c0,1.1,1.25,1.74,2.15,1.1l12.03-8.61c.75-.54.76-1.66,0-2.2Z"
      :fill="colorMode.preference === 'dark' ? '#fff' : '#2b303b'"
    />
    <circle cx="56.45" cy="49.14" r="13.46" style="fill: #d66100" />
    <path
      d="M54.61,52.77c0-1.05.13-1.89.38-2.52.25-.62.72-1.24,1.4-1.85s1.13-1.1,1.36-1.48c.23-.38.34-.78.34-1.2,0-1.27-.59-1.91-1.76-1.91-.56,0-1,.17-1.34.51-.33.34-.51.82-.52,1.42h-3.27c.01-1.44.48-2.56,1.39-3.38.91-.81,2.16-1.22,3.74-1.22s2.83.39,3.71,1.16,1.32,1.86,1.32,3.27c0,.64-.14,1.24-.43,1.81-.29.57-.79,1.2-1.5,1.89l-.91.87c-.57.55-.9,1.19-.98,1.93l-.04.69h-2.89ZM54.29,56.23c0-.5.17-.92.51-1.24.34-.33.78-.49,1.31-.49s.97.16,1.31.49c.34.33.51.74.51,1.24s-.17.9-.5,1.23c-.33.32-.78.48-1.33.48s-.99-.16-1.33-.48-.5-.73-.5-1.23Z"
      fill="#fff"
    />
  </svg>
</template>

<style scoped></style>
