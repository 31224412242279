<script setup lang="ts">
import UserAccountOptionCard from '~/components/user/user-account-option-card.vue';
import {
  TransactionSummary,
  BonusSummary,
} from '~/components/design-system/icons';
import { useAuthStore } from '~/stores/auth.store';
const router = useRouter();
const authStore = useAuthStore();
</script>
<template>
  <div class="grid-layout grid-cols-1 md:grid-cols-2 gap-2 p-3">
    <UserAccountOptionCard
      @click="authStore.setAccountModalPage('transaction-summary')"
    >
      <template #icon>
        <TransactionSummary class="w-2rem text-black dark:text-white" />
      </template>
      <template #title>
        <div class="font-bold text-base">{{ $t('transaction-summary') }}</div>
      </template>
      <template #body>
        <div class="text-xs">
          {{ $t('transaction-summary-message') }}
        </div>
      </template>
      <template #body-lg>
        <div class="text-xs">
          {{ $t('transaction-summary-message') }}
        </div>
      </template>
    </UserAccountOptionCard>
    <UserAccountOptionCard
      @click="authStore.setAccountModalPage('bonus-summary')"
    >
      <template #icon>
        <BonusSummary class="w-2rem text-black dark:text-white" />
      </template>
      <template #title>
        <div class="font-bold text-base">{{ $t('bonus-summary') }}</div>
      </template>
      <template #body>
        <div class="text-xs">{{ $t('bonus-summary-message') }}</div>
      </template>
      <template #body-lg>
        <div class="text-xs">{{ $t('bonus-summary-message') }}</div>
      </template>
    </UserAccountOptionCard>
  </div>
</template>
