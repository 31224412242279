<script setup lang="ts">
interface ISettingsBaseContainer {
  title?: string;
  titleClass?: string;
}
const props = withDefaults(defineProps<ISettingsBaseContainer>(), {
  title: '',
});
</script>
<template>
  <div
    class="border-none sm:border-1 dark:b-dark-500 b-light-200 border-round-md sm:py-1 text-black dark:text-white mx-auto max-w-26rem bg-white dark:bg-dark-800"
  >
    <div
      v-if="title"
      class="hidden sm:flex text-md font-bold my-2"
      :class="props?.titleClass"
    >
      {{ title }}
    </div>
    <slot />
  </div>
</template>
