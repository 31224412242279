<script setup lang="ts">
import UserAccountOptionCard from '~/components/user/user-account-option-card.vue';
import UserAllBalances from '~/components/user/user-all-balances.vue';
import { DepositIcon, WithdrawalIcon } from '~/components/design-system/icons';
import { useAuthStore } from '~/stores/auth.store';
const router = useRouter();
const auth = useAuthStore();
</script>
<template>
  <div>
    <div class="flex flex-wrap p-2 lg:p-3">
      <div class="hidden md:flex w-full">
        <UserAllBalances />
      </div>
      <div class="lg:flex w-full">
        <div class="w-full lg:mr-2 mb-2 lg:mb-0">
          <UserAccountOptionCard @click="auth.setAccountModalPage('deposit')">
            <template #icon>
              <DepositIcon class="text-black dark:text-white lg:mx-1" />
            </template>
            <template #title>
              <div class="font-bold text-base">{{ $t('deposit') }}</div>
            </template>
            <template #body>
              <div class="text-xs">{{ $t('deposit-message') }}</div>
            </template>
            <template #body-lg>
              <div class="text-xs">{{ $t('deposit-message') }}</div>
            </template>
          </UserAccountOptionCard>
        </div>
        <div class="w-full lg:ml-2">
          <UserAccountOptionCard
            @click="auth.setAccountModalPage('withdrawal')"
          >
            <template #icon>
              <WithdrawalIcon class="text-black dark:text-white lg:mx-1" />
            </template>
            <template #title>
              <div class="font-bold text-base capitalize">
                {{ $t('withdraw') }}
              </div>
            </template>
            <template #body>
              <div class="text-xs">{{ $t('withdrawal-message') }}</div>
            </template>
            <template #body-lg>
              <div class="text-xs">{{ $t('deposit-message') }}</div>
            </template>
          </UserAccountOptionCard>
        </div>
      </div>
    </div>
  </div>
</template>
