<script setup>
import { useAuthStore } from '~/stores/auth.store';
import ExclamationIcon from '../design-system/icons/ExclamationIcon.vue';
const auth = useAuthStore();
const zeroBalance = ref(0);
function toggleHideBalance() {
  auth.toggleBalances(!auth.displayAccountBalances);
}
const config = useRuntimeConfig();
</script>
<template>
  <div class="flex flex-column lg:flex-row gap-2 mb-3 dark:text-white w-full">
    <div
      class="text-2xl mr-2 cursor-pointer text-black dark:text-white"
      @click="toggleHideBalance()"
    >
      <i
        class="pi"
        :class="auth.displayAccountBalances ? 'pi-eye' : 'pi-eye-slash'"
      />
    </div>
    <div
      class="border-round-md flex align-items-center leading-tight text-xs bg-light-200 dark:bg-dark-900 px-1 h-8 w-full"
    >
      <div class="mx-1 w-full flex justify-content-between balance">
        <div class="font-bold capitalize">{{ $t('cash-balance') }}</div>
        <div class="flex">
          <div
            v-if="auth.accountBalances?.cashBalance < 0"
            class="flex justify-content-center"
          >
            <ExclamationIcon
              class="text-primary-gold-500 w-4"
              tooltip="could-not-retrieve-balances"
            />
          </div>
          <div
            v-else-if="auth.displayAccountBalances"
            class="truncate"
            v-html="
              useFormatCurrency(
                auth?.accountBalances?.cashBalance || zeroBalance.toFixed(2)
              )
            "
          />
          <div v-else>●●●●●</div>
        </div>
      </div>
    </div>
    <div
      class="border-round-md flex align-items-center leading-tight text-xs bg-light-200 dark:bg-dark-900 px-1 h-8 w-full"
    >
      <div class="mx-1 w-full flex justify-content-between balance">
        <div class="font-bold capitalize">{{ $t('bonus-balance') }}</div>
        <div class="flex">
          <div
            v-if="auth.accountBalances?.bonusBalance < 0"
            class="flex justify-content-center"
          >
            <ExclamationIcon
              class="text-primary-gold-500 w-4"
              tooltip="could-not-retrieve-balances"
            />
          </div>
          <div
            v-else-if="auth.displayAccountBalances"
            class="truncate"
            v-html="
              useFormatCurrency(
                auth.activeBonus?.totalAwardedBonusAmount ||
                  auth?.accountBalances?.playThroughBalance ||
                  zeroBalance
              )
            "
          />
          <div v-else>●●●●●</div>
        </div>
      </div>
    </div>
    <div
      class="border-round-md flex align-items-center leading-tight text-xs bg-light-200 dark:bg-dark-900 px-1 h-8 w-full"
    >
      <div class="mx-1 w-full flex justify-content-between balance">
        <div class="font-bold capitalize">{{ $t('total-balance') }}</div>
        <div class="flex">
          <div
            v-if="
              auth.accountBalances?.cashBalance < 0 ||
              auth.accountBalances.bonusBalance < 0
            "
            class="flex justify-content-center"
          >
            <ExclamationIcon
              class="text-primary-gold-500 w-4"
              tooltip="could-not-retrieve-balances"
            />
          </div>
          <div
            v-else-if="auth.displayAccountBalances"
            class="truncate"
            v-html="
              useFormatCurrency(
                auth?.accountBalances?.cashBalance +
                  auth.activeBonus?.totalAwardedBonusAmount ||
                  zeroBalance.toFixed(2)
              )
            "
          />
          <div v-else>●●●●●</div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.balance {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
</style>
