<script setup lang="ts">
interface IIconProps {
  path: string | string[];
  size?: number;
  filled?: boolean;
  stroked?: boolean;
  bindings?: Record<any, any> | null;
  class?: string;
}

const props = withDefaults(defineProps<IIconProps>(), {
  path: '',
  size: 24,
  filled: false,
  stroked: false,
  bindings: null,
  class: '',
});

const fullBindings = () => {
  return {
    fill: props.filled ? 'currentColor' : 'none',
    stroke: props.stroked ? 'currentColor' : '',
    viewBox: `0 0 ${props.size} ${props.size}`,
    width: props.size,
    height: props.size,
    class: 'w-full h-full ' + props.class,
    ...props.bindings,
  };
};
</script>
<template>
  <svg
    v-if="Array.isArray(props.path)"
    xmlns="http://www.w3.org/2000/svg"
    v-bind="fullBindings()"
    aria-hidden="true"
  >
    <path
      v-for="path in props.path"
      stroke-linecap="round"
      stroke-linejoin="round"
      :d="path"
    ></path>
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    v-bind="fullBindings()"
    aria-hidden="true"
  >
    <slot />
    <path stroke-linecap="round" stroke-linejoin="round" :d="path" />
  </svg>
</template>
