<script setup lang="ts">
import { useAuthStore } from '~/stores/auth.store';

const authStore = useAuthStore();
onBeforeMount(() => {
  if (authStore.getComplianceStatus < 1) {
    authStore.setAccountModalPage('document-verification');
  }
});
</script>
<template>
  <div>
    <LazyUserAccountSettingsHeader
      :title="`${$t('deposit')} (${authStore.currentUser.primaryMobileNumber})`"
      return-route="teller"
    />
    <div class="px-3 hidden md:block">
      <LazyUserAllBalances />
    </div>
    <LazyBankingThedeposits />
  </div>
</template>
