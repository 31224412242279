<script setup lang="ts">
import Button from '../design-system/button.vue';
import UserSettingsBaseContainer from './user-settings-base-container.vue';
import { trackingMethods } from '~~/plugins/analytics.client';
import GenericResponse from '~/components/user-interface/generic-error.vue';
import { useLocaleStore } from '~/stores/locale.store';
import { useSiteStore } from '~/stores/site.store';
import type { severity } from '~/interfaces/dto/general/toast-messages';
const { $authService } = useServices();
const site = useSiteStore();
const localeStore = useLocaleStore();

const { $t } = useNuxtApp();

// Values for normal drop down field
const dropDownvalue: globalThis.Ref<string> = ref('');
const langOptions: globalThis.Ref<string[]> = ref();
const commsOpts: globalThis.Ref = ref([
  { value: 'Email', text: $t('email') },
  { value: 'SMS', text: $t('sms') },
  { value: 'PushNotification', text: $t('push-notification') },
]);

const switchClasses: string =
  'flex justify-content-between md:justify-content-start align-items-center text-xs pb-0';

interface ICommunicationDetails {
  preferredCulture: string;
  platformName: string;
  preferredCommunicationChannel: string;
  allowPushNotificationMarketingMessages: false;
  allowPushNotificationInformativeMessages: false;
  allowPushNotificationReEngagingMessages: false;
  allowEmailMarketingMessages: false;
  allowEmailInformativeMessages: false;
  allowEmailReEngagingMessages: false;
  allowSmsMarketingMessages: false;
  allowSmsInformativeMessages: false;
  allowSmsReEngagingMessages: false;
}

const communicationDetails = ref<ICommunicationDetails>({
  preferredCulture: '',
  platformName: 'WebDesktop',
  preferredCommunicationChannel: '',
  allowPushNotificationMarketingMessages: false,
  allowPushNotificationInformativeMessages: false,
  allowPushNotificationReEngagingMessages: false,
  allowEmailMarketingMessages: false,
  allowEmailInformativeMessages: false,
  allowEmailReEngagingMessages: false,
  allowSmsMarketingMessages: false,
  allowSmsInformativeMessages: false,
  allowSmsReEngagingMessages: false,
});

// submit validation
let valid: globalThis.Ref<boolean> = ref(false);
let disabled: globalThis.ComputedRef<boolean> = computed(() => {
  return valid.value === false;
});

const activeLoader = ref<boolean>(false);
const responseType = ref<severity>();
const responseCode: globalThis.Ref<string> = ref('');
const displayError: globalThis.Ref<boolean> = ref(false);

async function updateSettings() {
  responseCode.value = '';
  activeLoader.value = true;
  const options = {
    preferredCulture: communicationDetails.value.preferredCulture,
    platformName: 'WebDesktop',
    preferredCommunicationChannel:
      communicationDetails.value.preferredCommunicationChannel,
    allowPushNotificationMarketingMessages:
      communicationDetails.value.allowPushNotificationMarketingMessages,
    allowPushNotificationInformativeMessages:
      communicationDetails.value.allowPushNotificationInformativeMessages,
    allowPushNotificationReEngagingMessages:
      communicationDetails.value.allowPushNotificationReEngagingMessages,
    allowEmailMarketingMessages:
      communicationDetails.value.allowEmailMarketingMessages,
    allowEmailInformativeMessages:
      communicationDetails.value.allowEmailInformativeMessages,
    allowEmailReEngagingMessages:
      communicationDetails.value.allowEmailReEngagingMessages,
    allowSmsMarketingMessages:
      communicationDetails.value.allowSmsMarketingMessages,
    allowSmsInformativeMessages:
      communicationDetails.value.allowSmsInformativeMessages,
    allowSmsReEngagingMessages:
      communicationDetails.value.allowSmsReEngagingMessages,
  };

  // send GA tracking response to update settings
  await trackingMethods.myAccountSettingsUpdate();

  await $authService
    .updateSubscriptions(options)
    .then((data) => {
      activeLoader.value = false;
      if (data) {
        responseType.value = 'success';
        responseCode.value = '0';
      } else {
        responseType.value = 'danger';
        responseCode.value = '1';
      }
    })
    .catch((error) => {
      activeLoader.value = false;
      responseType.value = 'danger';
      responseCode.value = '1';
    });
}
function validate(value: string) {
  if (value === undefined) {
    valid.value = false;
    return;
  } else if (value.length < 1) {
    valid.value = false;
    return;
  } else {
    valid.value = true;
  }
}

function createLangSelection() {
  if (site.getConfig.cultures) {
    localeStore.setLocaleOptions(site.getConfig.cultures);
    langOptions.value = localeStore.getLocaleOptions;
  }
}

onBeforeMount(async () => {
  activeLoader.value = true;
  displayError.value = false;
  try {
    const data = await $authService.fetchSubscriptions();
    // remember to map culture to culture translation for the view
    communicationDetails.value.preferredCulture =
      data.preferredCulture || 'en-US';
    communicationDetails.value.platformName = data.platformName || 'WebDesktop';
    communicationDetails.value.preferredCommunicationChannel =
      data.preferredCommunicationChannel || ''; // need to create function to map to translation
    communicationDetails.value.allowPushNotificationMarketingMessages =
      data.allowPushNotificationMarketingMessages || false;
    communicationDetails.value.allowPushNotificationInformativeMessages =
      data.allowPushNotificationInformativeMessages || false;
    communicationDetails.value.allowPushNotificationReEngagingMessages =
      data.allowPushNotificationReEngagingMessages || false;
    communicationDetails.value.allowEmailMarketingMessages =
      data.allowEmailMarketingMessages || false;
    communicationDetails.value.allowEmailInformativeMessages =
      data.allowEmailInformativeMessages || false;
    communicationDetails.value.allowEmailReEngagingMessages =
      data.allowEmailReEngagingMessages || false;
    communicationDetails.value.allowSmsMarketingMessages =
      data.allowSmsMarketingMessages || false;
    communicationDetails.value.allowSmsInformativeMessages =
      data.allowSmsInformativeMessages || false;
    communicationDetails.value.allowSmsReEngagingMessages =
      data.allowSmsReEngagingMessages || false;
    activeLoader.value = false;
  } catch (e) {
    activeLoader.value = false;
    console.error(e);
  }
});

onMounted(async () => {
  createLangSelection();
});

watchEffect(() => {
  validate(communicationDetails.value.preferredCommunicationChannel);
});
</script>

<template>
  <GenericResponse v-if="displayError" class="h-min" state="Error">
    {{ $t('there-was-a-problem') }}
  </GenericResponse>
  <UserSettingsBaseContainer class="mb-3" v-else>
    <!--    Channel and language-->
    <LazyUserInterfaceGenericLoader v-if="activeLoader" :standalone="true" />
    <div class="user-comm-pref" v-else>
      <!--      <div class="px-2">-->
      <!--        <div class="">-->
      <!--          <p class="font-bold mt-3 sm:mt-2 mb-2 text-base">-->
      <!--            {{ $t('manage-communication') }}-->
      <!--          </p>-->
      <!--        </div>-->
      <!--        <div class="text-xs">-->
      <!--          <p class="my-0">-->
      <!--            {{ $t('manage-communication-message') }}-->
      <!--          </p>-->
      <!--        </div>-->
      <!--        <div class="grid">-->
      <!--          <div class="field col-12 mb-0 mt-3">-->
      <!--            <label class="text-xs font-bold" for="dropdown">-->
      <!--              {{ $t('system-messages-channel') }}-->
      <!--            </label>-->
      <!--            <Dropdown-->
      <!--              id="dropdown"-->
      <!--              v-model="communicationDetails.preferredCommunicationChannel"-->
      <!--              :options="commsOpts"-->
      <!--              option-label="text"-->
      <!--              option-value="value"-->
      <!--              :placeholder="$t('communication-channel')"-->
      <!--            />-->
      <!--          </div>-->
      <!--          <div class="field col-12 mb-0">-->
      <!--            <label class="text-xs font-bold" for="dropdown">-->
      <!--              {{ $t('communication-language') }}-->
      <!--            </label>-->
      <!--            <Dropdown-->
      <!--              id="dropdown"-->
      <!--              option-label="translation"-->
      <!--              option-value="cultureCode"-->
      <!--              v-model="communicationDetails.preferredCulture"-->
      <!--              :options="langOptions"-->
      <!--              :placeholder="$t('select-language')"-->
      <!--            />-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <hr class="mt-2" />-->

      <!-- Marketing messages-->
      <div class="px-2">
        <div class="mt-3">
          <p class="font-bold text-base mt-0 mb-1">
            {{ $t('marketing-messages') }}
          </p>
        </div>
        <div class="text-xs">
          <p class="mt-0 mb-3">
            {{ $t('marketing-message-description') }}
          </p>
        </div>

        <!-- Channel and language-->
        <div class="grid-layout grid-cols-1 md:grid-cols-3 gap-2">
          <div :class="switchClasses">
            <label for="marketing-email" class="mr-2">
              {{ $t('email') }}
            </label>
            <InputSwitch
              inputId="email-marketing"
              v-model="communicationDetails.allowEmailMarketingMessages"
            />
          </div>
          <hr class="md:hidden sm:block xs:block" />
          <div :class="switchClasses">
            <label for="push-marketing" class="mr-2">
              {{ $t('push') }}
            </label>
            <InputSwitch
              inputId="push-marketing"
              v-model="
                communicationDetails.allowPushNotificationMarketingMessages
              "
            />
          </div>
          <hr class="md:hidden sm:block xs:block" />
          <div :class="switchClasses">
            <label for="sms-marketing" class="mr-2">
              {{ $t('sms') }}
            </label>
            <InputSwitch
              inputId="sms-marketing"
              v-model="communicationDetails.allowSmsMarketingMessages"
            />
          </div>
        </div>
      </div>
      <hr class="mt-3" />

      <!-- Informative messages-->
      <div class="px-2">
        <div class="">
          <p class="font-bold text-base mt-3 mb-1">
            {{ $t('informative-messages') }}
          </p>
        </div>
        <div class="text-xs">
          <p class="mt-0 mb-3">
            {{ $t('informative-messages-description') }}
          </p>
        </div>
        <!-- Channel and language-->
        <div class="grid-layout grid-cols-1 md:grid-cols-3 gap-2">
          <div :class="switchClasses">
            <label for="email-informative" class="mr-2">
              {{ $t('email') }}
            </label>
            <InputSwitch
              inputId="email-informative"
              v-model="communicationDetails.allowEmailInformativeMessages"
            />
          </div>
          <hr class="md:hidden sm:block xs:block" />
          <div :class="switchClasses">
            <label for="push-informative" class="mr-2">
              {{ $t('push') }}
            </label>
            <InputSwitch
              inputId="push-informative"
              v-model="
                communicationDetails.allowPushNotificationInformativeMessages
              "
            />
          </div>
          <hr class="md:hidden sm:block xs:block" />
          <div :class="switchClasses">
            <label for="sms-informative" class="mr-2">
              {{ $t('sms') }}
            </label>
            <InputSwitch
              inputId="sms-informative"
              v-model="communicationDetails.allowSmsInformativeMessages"
            />
          </div>
        </div>
      </div>
      <hr class="mt-3" />
      <!-- Engaging messages-->
      <!--      <div class="px-2">-->
      <!--        <div class="">-->
      <!--          <p class="font-bold text-base mt-3 mb-1">-->
      <!--            {{ $t('engaging-messages') }}-->
      <!--          </p>-->
      <!--        </div>-->
      <!--        <div class="text-xs">-->
      <!--          <p class="mt-0 mb-3">-->
      <!--            {{ $t('engaging-messages-description') }}-->
      <!--          </p>-->
      <!--        </div>-->
      <!--        &lt;!&ndash; Channel and language&ndash;&gt;-->
      <!--        <div class="grid-layout grid-cols-1 md:grid-cols-3 gap-2">-->
      <!--          <div :class="switchClasses">-->
      <!--            <label for="email-engaging" class="mr-2">-->
      <!--              {{ $t('email') }}-->
      <!--            </label>-->
      <!--            <InputSwitch-->
      <!--              inputId="email-engaging"-->
      <!--              v-model="communicationDetails.allowEmailReEngagingMessages"-->
      <!--            />-->
      <!--          </div>-->
      <!--          <hr class="md:hidden sm:block xs:block" />-->
      <!--          <div :class="switchClasses">-->
      <!--            <label for="push-engaging" class="mr-2">-->
      <!--              {{ $t('push') }}-->
      <!--            </label>-->
      <!--            <InputSwitch-->
      <!--              inputId="push-engaging"-->
      <!--              v-model="-->
      <!--                communicationDetails.allowPushNotificationReEngagingMessages-->
      <!--              "-->
      <!--            />-->
      <!--          </div>-->
      <!--          <hr class="md:hidden sm:block xs:block" />-->
      <!--          <div :class="switchClasses">-->
      <!--            <label for="sms-engaging" class="mr-2">-->
      <!--              {{ $t('sms') }}-->
      <!--            </label>-->
      <!--            <InputSwitch-->
      <!--              inputId="sms-engaging"-->
      <!--              v-model="communicationDetails.allowSmsReEngagingMessages"-->
      <!--            />-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <hr class="mt-3" />-->
      <div class="text-sm w-full p-2">
        <Button
          class="mt-2 uppercase w-full justify-content-center text-sm"
          @click="updateSettings"
          :disabled="disabled"
        >
          {{ $t('update') }}
        </Button>
      </div>
    </div>
    <div>
      <GenericResponse v-if="responseCode" :state="responseType">
        {{ $t(`response-code.${responseCode}`) }}
      </GenericResponse>
    </div>
  </UserSettingsBaseContainer>
</template>

<style lang="scss">
.user-comm-pref .p-inputswitch-slider {
  border: none;
}
</style>

<style lang="scss" scoped>
label {
  text-transform: capitalize;
  font-family: Inter;
  font-size: 12px;
  font-weight: bold;
}
hr {
  width: 100%;
  border-color: #dae0ed;
  @media (min-width: 576px) {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  margin-block-start: 0;
  margin-block-end: 0;
}
.dark {
  hr {
    border-color: #464f60;
  }
}
</style>
