<script setup lang="ts">
const Icon = useIcon();
import { TransactionSummary } from "assets/data/icons";

interface IBindings {
    "stroke-width": string;
    "fill-rule": string;
    "clip-rule": string;
    "stroke-linecap": string;
    "stroke-linejoin": string;
    viewBox: string;
}

const bindings: IBindings = {
  "stroke-width": "2",
  "fill-rule": "evenodd",
  "clip-rule": "evenodd",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  viewBox: "0 0 32 32",
};
</script>
<template>
  <Icon
    :path="TransactionSummary"
    class="w-2rem text-black dark:text-white"
    :bindings="bindings"
    filled
  />
</template>
