<script setup lang="ts">
import UserSettingsBaseContainer from './user-settings-base-container.vue';
import Input from '~/components/design-system/form/input.vue';
import Button from '~/components/design-system/button.vue';
import { onClickOutside } from '@vueuse/core';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import { useLocaleStore } from '../../stores/locale.store';
import GenericError from '~/components/user-interface/generic-error.vue';
import { trackingMethods } from '~~/plugins/analytics.client';
import { useToast } from 'primevue/usetoast';

const { $t } = useNuxtApp();
const prepareMobileNumber = usePrepareMobileNumber;
const localeStore = useLocaleStore();

interface IPersonalDetails {
  email: string;
  username: string;
  firstname: string;
  lastname: string;
  languageOption: string;
  dialingCode: string;
  countryCode: string;
  idNumberType?: string;
  idNumber?: number | string;
  signupCode?: string;
  referralCode?: string;
  sourceOfFunds: string;
  dateOfBirth: Date;
  sourceOfIncome: string;
}

interface IFieldErrors {
  firstname: string;
  lastname: string;
  dateOfBirth: string;
  email: string;
  language: string;
  idNumber: string;
  idNumberType: string;
  sourceFunds: string;
}

interface IIdTypeOptions {
  id: string;
  description: string;
}

interface Ilanguages {
  key: string;
  value: string;
}

interface IErrorsObject {
  isEmpty: string;
  isEmailValid: string;
  emptyString: string;
}

// used for when changing source of income is enabled
const sourceFunds: Ref<string[]> = ref([
  'Salary',
  'Wages',
  'Self Employed',
  'Inheritance',
  'Savings',
  'Investment Income',
  'Rental Income',
  'Retirement Income',
  'Government Assistance',
]);

const langOptions = ref();
const toast = useToast();
let mobileNo: string[] = reactive([]);
const newMobile: globalThis.Ref<string> = ref('');
const popUpOptions: globalThis.Ref<string> = ref('');
const primaryNo: globalThis.Ref<string> = ref('');
const target = ref(null);
const active: globalThis.Ref<boolean> = ref(false);
const idType: globalThis.Ref<string> = ref('ID Number');
const { $authService } = useServices();
const errorsObject: globalThis.Ref<IErrorsObject> = ref({
  isEmpty: 'This field cannot be empty.',
  isEmailValid: 'Please provide a valid email address.',
  emptyString: '',
});
const activeLoader: globalThis.Ref<boolean> = ref(false);
const userDetails = ref<IPersonalDetails>({
  email: '',
  username: '',
  firstname: '',
  lastname: '',
  languageOption: '',
  dialingCode: '',
  countryCode: '',
  idNumberType: '',
  idNumber: null,
  signupCode: '',
  referralCode: '',
  sourceOfFunds: '',
  sourceOfIncome: '',
  dateOfBirth: new Date('01-01-1000'),
});
const errorCode: globalThis.Ref<string> = ref('');
const fieldErrors: globalThis.Ref<IFieldErrors> = ref({
  username: '',
  firstname: '',
  lastname: '',
  dob: '',
  email: '',
  language: '',
  idNumber: '',
  idNumberType: '',
  sourceFunds: '',
});
const auth = useAuthStore();
const site = useSiteStore();
const idTypeOptions: IIdTypeOptions[] = [
  {
    id: '00000000-d474-0000-4444-000000000001',
    description: 'Id Booklet',
  },
  {
    id: '00000000-d474-0000-4444-000000000002',
    description: 'Id Card',
  },
  {
    id: '00000000-d474-0000-4444-000000000003',
    description: 'Passport',
  },
  {
    id: '00000000-d474-0000-4444-000000000004',
    description: 'Drivers License',
  },
  {
    id: '00000000-d474-0000-4444-000000000005',
    description: 'Birth Certificate',
  },
  {
    id: '00000000-d474-0000-4444-000000000006',
    description: 'Unabridged Birth Certificate',
  },
];
const mobilePopUp = ref(null);
onClickOutside(mobilePopUp, (event) => (active.value = false));

function addMobile() {
  newMobile.value = '27' + formatNo(newMobile.value);
  if (mobileNo.includes(newMobile.value) || newMobile.value === '') {
    //GenericError add in
  } else {
    mobileNo.push(newMobile.value);
    $authService.addMobileNumber({
      accountId: auth.userId,
      countryCode: auth.currentUser.dialingCode,
      mobileNumber: newMobile.value,
    });
  }
  newMobile.value = '';
}

function formatNo(mobile: string): string {
  return mobile.replace(/\s+/g, '').slice(-9); //last 9 digits + strip spaces
}

function deleteMobile(mobile: string) {
  const phone = prepareMobileNumber(mobile);
  if (phone !== '27' + formatNo(primaryNo.value)) {
    $authService.deleteMobileNumber({
      accountId: auth.userId,
      countryCode: site.getRegion,
      mobileNumber: phone,
    });

    mobileNo.splice(mobileNo.indexOf(phone), 1);
  } else {
    //TODO: when business enables adding and removing numbers
    // Error Toast - "please change your primary number in order to delete this one"
  }
}

function isPrimary(mobile: string): boolean {
  return primaryNo.value === mobile;
}

function changePrimary(mobile: string) {
  mobileNo.unshift(mobileNo.splice(mobileNo.indexOf(mobile), 1)[0]); //move primary to top of array
  primaryNo.value = mobile;
}

function emailVerification(email: string): boolean {
  var emailStatus = new RegExp(
    '^[A-Za-z0-9\\s*._%+-]+@[A-Za-z0-9\\s*.-]+\\.[A-Za-z\\s*]{2,}$',
    'i'
  );
  return emailStatus.test(email);
}

function formatBirthday() {
  const dob = userDetails.value.dateOfBirth;
  userDetails.value.dateOfBirth = new Date(dob).toLocaleDateString('en-GB');
}

function createLangSelection() {
  if (site.getConfig.cultures) {
    localeStore.setLocaleOptions(site.getConfig.cultures);
    langOptions.value = localeStore.getLocaleOptions;
  }
}

onMounted(async () => {
  await populateUserDetails();
});

async function update() {
  errorCode.value = '';
  const userData: any = { ...userDetails.value };

  if (userData.signupCode == '') delete userData.signupCode;

  if (userData.referralCode == '') delete userData.referralCode;

  delete userData.idNumber;
  delete userData.idNumberType;

  const emailStatus: boolean = emailVerification(userData.email);
  if (!emailStatus) {
    fieldErrors.value.email = errorsObject.value.isEmailValid;
  } else {
    fieldErrors.value.email = '';
  }
  const isEmpty = Object.values(fieldErrors.value).find((item) => {
    return item != '';
  });

  if (!isEmpty) {
    activeLoader.value = true;

    // TODO: When business comes back with other fields that we need to update refactor to
    // dynamically add fields to array to pass through to update
    const request = {
      id: userData.id,
      username: userData.username,
      countryCode: userData.countryCode,
      email: userData.email,
    };

    // send GA tracking response to update user details
    await trackingMethods.myAccountDetailsUpdate();

    await $authService
      .editUser(request)
      .then((data) => {
        populateUserDetails();
        activeLoader.value = false;
        if (data.isSuccessful) {
          //If Successful
          toast.add({
            severity: 'success',
            summary: $t('success'),
            detail: $t(`pers-details-updated-succ`),
            life: 3000,
            group: 'toast',
          });
        } else {
          errorCode.value = data.error.code;
        }
      })
      .catch((e) => {
        activeLoader.value = false;
        errorCode.value = e.error.code;
      });
  }
}

async function populateUserDetails() {
  activeLoader.value = true;
  try {
    await $authService.getUserExtended().then(({ data }) => {
      userDetails.value = { ...userDetails.value, ...data };
      formatBirthday();
      primaryNo.value = data?.primaryMobileNumber || '';
      mobileNo.push(primaryNo.value);
      data?.secondaryMobileNumbers?.forEach((no) => {
        mobileNo.push(no);
      });
    });

    activeLoader.value = false;
  } catch (error) {
    activeLoader.value = false;
    console.error(error);
  }
  createLangSelection();
}
/**
 * TODO:
 * What happens when a language gets changed (fire an event to translate whole site?) - check hamburger dropdown
 * Check with business what other fields going to be added and map accordingly
 * Error messages!
 */
</script>

<template>
  <Toast
    position="bottom-center"
    group="toast"
    :pt="{
      root: { style: 'width: 80%;' },
    }"
  />
  <UserSettingsBaseContainer
    :title="$t('personal-details')"
    class="sm:mb-3 mt-3"
    titleClass="px-3"
  >
    <LazyUserInterfaceGenericLoader v-if="activeLoader" :standalone="true" />

    <div v-else>
      <div class="flex flex-column sm:mb-1 w-max-24rem personal-details px-3">
        <Input
          form-key="username"
          :label="$t('username')"
          class="w-full mb-2"
          v-model="userDetails.username"
          disabled
        />

        <small class="p-error text-xs pb-1">{{ fieldErrors.username }}</small>
        <Input
          form-key="firstName"
          :label="$t('first-name')"
          class="w-full mb-2"
          v-model="userDetails.firstname"
          disabled
        />

        <small class="p-error text-xs pb-1">{{ fieldErrors.firstname }}</small>
        <Input
          form-key="surname"
          :label="$t('last-name')"
          class="w-full mb-2"
          v-model="userDetails.lastname"
          disabled
        />
        <small class="p-error text-xs">{{ fieldErrors.lastname }}</small>
        <Input
          form-key="dateOfBirth"
          :label="$t('date-of-birth')"
          class="w-full mb-2"
          v-model="userDetails.dateOfBirth"
          disabled
        />
        <small class="p-error text-xs">{{ fieldErrors.dateOfBirth }}</small>

        <Input
          class="w-full mb-2"
          v-model="userDetails.idNumber"
          formKey="idNumber"
          :label="userDetails.idNumberType"
          disabled
        />

        <small class="p-error text-xs">
          {{ fieldErrors.idNumberType || fieldErrors.idNumber }}
        </small>
        <Input
          :label="$t('source-of-income')"
          class="w-full mb-2"
          :model-value="
            $t(userDetails.sourceOfFunds || userDetails.sourceOfIncome)
          "
          disabled
        />
        <small class="p-error text-xs">{{ fieldErrors.sourceFunds }}</small>
        <Dropdown
          v-if="langOptions?.length > 1"
          :options="langOptions"
          option-label="translation"
          option-value="cultureCode"
          :placeholder="$t('select-language')"
          v-model="userDetails.languageOption"
          class="mb-2"
        />
        <small class="p-error text-xs">{{ fieldErrors.language }}</small>

        <!--      <div class="text-md font-semibold mt-3">-->
        <!--        {{ $t('account-phone-numbers') }}-->
        <!--      </div>-->
        <!--      <div class="text-xs">{{ $t('account-phone-numbers-message') }}</div>-->
        <!--      <div class="col-12 gap-2 px-0">-->
        <!--        <div class="p-inputgroup w-auto mb-1">-->
        <!--          <PrefixInput-->
        <!--            class="b-right-0 border-noround font-bold"-->
        <!--            label="Mobile number"-->
        <!--            type="number"-->
        <!--            v-model="newMobile"-->
        <!--            :prefix-copy="`+` + userDetails.dialingCode"-->
        <!--          />-->
        <!--          <span-->
        <!--            class="p-inputgroup-addon p-0 bg-light-50 dark:bg-dark-800 b-1 b-left-0 b-light-400 dark:b-dark-500 border-round-right-md"-->
        <!--          >-->
        <!--            <Button-->
        <!--              @click="addMobile()"-->
        <!--              group="primary"-->
        <!--              icon="pi pi-plus"-->
        <!--              size="icon-sm"-->
        <!--            />-->
        <!--          </span>-->
        <!--        </div>-->
        <!--        <div class="p-inputgroup mb-1" v-for="(mobile, index) in mobileNo">-->
        <!--          <PrefixInput-->
        <!--            :disabled="true"-->
        <!--            class="b-right-0 border-noround pl-3 opacity-100 font-bold"-->
        <!--            label="Mobile number"-->
        <!--            :model-value="-->
        <!--              `0` +-->
        <!--              [mobile.slice(2, 4), mobile.slice(4, 7), mobile.slice(7)].join(-->
        <!--                ' '-->
        <!--              )-->
        <!--            "-->
        <!--            :prefix-copy="`+` + userDetails.dialingCode"-->
        <!--          >-->
        <!--            <template #primaryMobile v-if="isPrimary(mobile)">-->
        <!--              <span class="dot" />-->
        <!--            </template>-->
        <!--          </PrefixInput>-->

        <!--          <span-->
        <!--            class="p-inputgroup-addon p-0 dark:bg-dark-800 bg-light-50 b-1 b-left-0 b-light-400 dark:b-dark-500 border-round-right-md cursor-pointer"-->
        <!--            @click="-->
        <!--              active = !active;-->
        <!--              popUpOptions = mobile;-->
        <!--            "-->
        <!--          >-->
        <!--            <i-->
        <!--              class="pi pi-ellipsis-h dark:text-white text-black"-->
        <!--              v-if="!isPrimary(mobile)"-->
        <!--            />-->
        <!--          </span>-->
        <!--          <div-->
        <!--            v-if="popUpOptions === mobile && active && !isPrimary(mobile)"-->
        <!--            class="popUpOptions absolute flex flex-column b-1 dark:b-dark-500 b-light-500 border-round-md dark:bg-dark-800 bg-light-50"-->
        <!--            ref="mobilePopUp"-->
        <!--          >-->
        <!--            <div class="spike dark:bg-dark-800"></div>-->
        <!--            <div-->
        <!--              class="b-0 b-bottom-1 dark:b-dark-500 b-light-500 flex flex-row cursor-pointer"-->
        <!--              @click="changePrimary(mobile)"-->
        <!--            >-->
        <!--              <div-->
        <!--                class="dot w-2 h-2 mx-2 my-auto align-content-center static"-->
        <!--              ></div>-->
        <!--              <p-->
        <!--                class="dark:text-white text-black font-normal text-xs text-left my-1"-->
        <!--              >-->
        <!--                {{ $t('make-primary-num') }}-->
        <!--              </p>-->
        <!--            </div>-->
        <!--            <div-->
        <!--              class="mx-2 flex flex-row cursor-pointer align-content-center flex-wrap"-->
        <!--              @click="deleteMobile(mobile)"-->
        <!--            >-->
        <!--              <TrashIcon class="w-3 dark:stroke-white stroke-black mt-1" />-->
        <!--              <p-->
        <!--                class="dark:text-white text-black font-normal text-xs text-left ml-1 my-1"-->
        <!--              >-->
        <!--                {{ $t('delete') }}-->
        <!--              </p>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <!--      </div>-->
      </div>
      <div
        class="update-details b-top-1 b-0 w-full dark:b-dark-600 b-light-200 px-3"
      >
        <p class="my-2 text-base font-bold dark:text-white text-dark-700">
          {{ $t('update-email') }}
        </p>
        <Input
          formKey="email"
          :label="$t('email')"
          class="w-full text-sm font-bold"
          v-model="userDetails.email"
          :class="fieldErrors.email ? 'mb-1' : 'mb-2'"
        />
        <small v-if="fieldErrors.email" class="p-error text-xs mb-2">
          {{ fieldErrors.email }}
        </small>
        <Button class="w-full justify-content-center my-3" @click="update()">
          {{ $t('update') }}
        </Button>
      </div>
    </div>
  </UserSettingsBaseContainer>
  <GenericError v-if="errorCode" state="danger" class="max-w-72 mx-auto mb-2">
    {{ $t(`update-details-${errorCode}`) }}
  </GenericError>
</template>

<style scoped lang="scss">
.popUpOptions {
  width: 328px;
  z-index: 100;
  margin: 40px 0 0 52px;
}

.dot {
  height: 8px;
  width: 8px;
  background-color: var(--primary-bluebase);
  border-radius: 100%;
  position: absolute;
  right: 198px;
  top: 10px;
}

/** Breakpoints  */

@media screen and (max-width: 575px) {
  .dot {
    right: 88px;
    top: 13px;
  }

  .popUpOptions {
    width: 250px;
    margin: 37px 0 0 20px;
  }
}

@media screen and (max-width: 359px) {
  .dot {
    right: 48px;
    top: 13px;
  }

  .popUpOptions {
    width: 200px;
    margin: 37px 0 0 31px;
  }
}

//spike refers to the little ^ on the right side of the popUpOptions which indicates to the user which mobile number they are working with
.dark {
  .spike {
    border-top: solid #464f60;
    border-left: solid #464f60;
  }
}

.spike {
  height: 10px;
  width: 10px;
  background: #ffffff;
  transform: rotate(45deg);
  border-radius: 0 0 2px 0;
  border-top-width: 1px !important;
  border-left-width: 1px !important;
  border-top: solid #dae0ed;
  border-left: solid #dae0ed;
  position: absolute;
  right: 0.7em;
  bottom: 2.69em;
}
</style>

<style lang="scss">
/* Remove up down arrow from input type number*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

// styling the non editable personal details fields
.personal-details {
  & .p-disabled {
    opacity: 1;
  }

  & label.input-label {
    color: #828da4;
  }

  & input.p-inputtext {
    background: #dae0ed;
    color: #828da4;
    -webkit-text-fill-color: #828da4;
    border: none;
    font-weight: 700;
    font-size: 14px;
  }
}

// Dark mode
.dark .personal-details {
  & label.input-label {
    color: #6f7c97;
  }

  & input.p-inputtext {
    background: #121417;
    color: #6f7c97;
    -webkit-text-fill-color: #6f7c97;
  }
}
</style>
